import authRoles from '../auth/authRoles';

const navigationConfig = [
	{
		id: 'ameroAdmin',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'organisations',
				title: 'Organisationer',
				type: 'item',
				icon: 'business',
				auth: authRoles.ameroAdmin,
				url: '/apps/organisations',
				exact: true,
			},
		],
	},
	{
		id: 'user',
		title: '',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/apps/dashboard',
				exact: true,
			},
			{
				id: 'customers',
				title: 'Kunder',
				type: 'item',
				icon: 'people',
				url: '/apps/customers',
				exact: true,
			},
			{
				id: 'orders',
				title: 'Ordrer',
				type: 'item',
				icon: 'shopping_cart',
				url: '/apps/orders',
				exact: true,
			},
		],
	},
	{
		id: 'admin-diverse',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'reports',
				title: 'Rapporter',
				type: 'item',
				icon: 'library_books',
				url: '/apps/reports',
				auth: authRoles.user,
				exact: true,
			},
		],
	},
];

export default navigationConfig;
