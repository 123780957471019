import React from 'react';
import DashboardPage from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/DashboardPage';

const DashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/apps/dashboard',
			element: <DashboardPage />,
		},
	],
};

export default DashboardAppConfig;
