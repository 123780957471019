import error404PageConfig from '@ameroservices-platform/shipfromstore-backend/app/main/apps/error/404/error404PageConfig';
import error500PageConfig from '@ameroservices-platform/shipfromstore-backend/app/main/apps/error/500/error500PageConfig';
import CustomersAppConfig from './customers/CustomersAppConfig';
import OrganisationsAppConfig from './organisations/OrganisationsAppConfig';
import OrdersAppConfig from './orders/OrdersAppConfig';
import SysAdminAppConfig from './sysAdmin/SysAdminAppConfig';
import DashboardAppConfig from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/DashboardAppConfig';
import ExportJobsAppConfig from '@ameroservices-platform/shipfromstore-backend/app/main/apps/exportJobs/ExportJobsAppConfig';

const appsConfigs = [
	CustomersAppConfig,
	DashboardAppConfig,
	ExportJobsAppConfig,
	OrganisationsAppConfig,
	OrdersAppConfig,
	SysAdminAppConfig,
	error404PageConfig,
	error500PageConfig,
];

export default appsConfigs;
