import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import appsConfigs from '@ameroservices-platform/shipfromstore-backend/app/main/apps/appsConfigs';
import LoginConfig from '@ameroservices-platform/shipfromstore-backend/app/main/login/LoginConfig';
import LogoutConfig from '@ameroservices-platform/shipfromstore-backend/app/main/logout/LogoutConfig';
import FuseLoading from '@ameroservices-platform/shipfromstore-backend/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import authRoles from '@ameroservices-platform/shipfromstore-backend/app/auth/authRoles';

const routeConfigs = [...appsConfigs];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.user),
	...FuseUtils.generateRoutesFromConfigs([LogoutConfig, LoginConfig]),
	{
		path: '/',
		element: <Navigate to="apps/orders" />,
	},
	{
		path: 'loading',
		element: <FuseLoading />,
	},
	{
		path: '*',
		element: <Navigate to="apps/error/404" />,
	},
];

export default routes;
