import SharedConfirmationDialog from '@ameroservices-platform/shared/ui-components/ConfirmationDialog';
import { selectMainTheme } from '../store/fuse/settingsSlice';
import { useSelector } from 'react-redux';

function ConfirmationDialog(props) {
	const mainTheme = useSelector(selectMainTheme);
	return <SharedConfirmationDialog mainTheme={mainTheme} {...props} />;
}

export default ConfirmationDialog;
