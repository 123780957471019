import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useReducer, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch } from 'react-redux';
import OrganisationSelectorChip from './OrganisationSelectorChip';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { showMessage } from '@ameroservices-platform/shipfromstore-backend/app/store/fuse/messageSlice';
import { makeStyles } from '@mui/styles';
import {
	Icon,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import Paper from '@mui/material/Paper';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';

const organisationsListener = (callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.where('deleted', '==', false)
		.where('type', '==', organisationType.SHIP_FROM_STORE)
		.onSnapshot((querySnapshot) => {
			const organisations = [];
			querySnapshot.forEach((doc) => {
				const docData = doc.data();
				organisations.push({ ...docData, id: doc.id });
			});
			console.log('possible organisations', organisations);
			callback(organisations);
		});
};

function renderInputComponent(inputProps) {
	const { variant, classes, inputRef = () => {}, ref, ...other } = inputProps;
	return (
		<div className="w-full relative">
			{variant === 'basic' ? (
				// Outlined
				<>
					<TextField
						fullWidth
						InputProps={{
							inputRef: (node) => {
								ref(node);
								inputRef(node);
							},
							classes: {
								input: clsx(classes.input, 'py-0 px-16 h-48 ltr:pr-48 rtl:pl-48'),
								notchedOutline: 'rounded-8',
							},
						}}
						variant="outlined"
						{...other}
					/>
					<Icon
						className="absolute top-0 ltr:right-0 rtl:left-0 h-48 w-48 p-12 pointer-events-none"
						color="action"
					>
						search
					</Icon>
				</>
			) : (
				// Standard
				<TextField
					fullWidth
					InputProps={{
						disableUnderline: true,
						inputRef: (node) => {
							ref(node);
							inputRef(node);
						},
						classes: {
							input: clsx(classes.input, 'py-0 px-16 h-64'),
						},
					}}
					variant="standard"
					{...other}
				/>
			)}
		</div>
	);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.name, query);
	const parts = parse(suggestion.name, matches);

	return (
		<MenuItem selected={isHighlighted} component="div">
			<ListItemIcon className="min-w-40">
				{suggestion.icon ? (
					<Icon>{suggestion.icon}</Icon>
				) : (
					<span className="text-20 w-24 font-bold uppercase text-center">{suggestion.name[0]}</span>
				)}
			</ListItemIcon>
			<ListItemText
				primary={parts.map((part, index) =>
					part.highlight ? (
						<span key={String(index)} style={{ fontWeight: 600 }}>
							{part.text}
						</span>
					) : (
						<strong key={String(index)} style={{ fontWeight: 300 }}>
							{part.text}
						</strong>
					)
				)}
			/>
		</MenuItem>
	);
}

function getSuggestions(value, data) {
	const inputValue = _.deburr(value.trim()).toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;

	return inputLength === 0
		? []
		: data.filter((suggestion) => {
				const keep = count < 10 && match(suggestion.name, inputValue).length > 0;

				if (keep) {
					count += 1;
				}

				return keep;
		  });
}

function getSuggestionValue(suggestion) {
	return suggestion.name;
}

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(),
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	input: {
		transition: theme.transitions.create(['background-color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short,
		}),
		'&:focus': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	inputIcon: {
		color: theme.palette.secondary.main,
	},
}));

const initialState = {
	searchText: '',
	search: false,
	navigation: null,
	suggestions: [],
	noSuggestions: false,
};

function reducer(state, action) {
	switch (action.type) {
		case 'open': {
			return {
				...state,
				opened: true,
			};
		}
		case 'close': {
			return {
				...state,
				opened: false,
				searchText: '',
			};
		}
		case 'setSearchText': {
			return {
				...state,
				searchText: action.value,
			};
		}
		case 'setNavigation': {
			return {
				...state,
				navigation: action.value,
			};
		}
		case 'updateSuggestions': {
			const suggestions = getSuggestions(action.value, state.navigation);
			const isInputBlank = action.value.trim() === '';
			const noSuggestions = !isInputBlank && suggestions.length === 0;

			return {
				...state,
				suggestions,
				noSuggestions,
			};
		}
		case 'clearSuggestions': {
			return {
				...state,
				suggestions: [],
				noSuggestions: false,
			};
		}
		case 'decrement': {
			return { count: state.count - 1 };
		}
		default: {
			throw new Error();
		}
	}
}

function OrganisationSelector(props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const rootDispatch = useDispatch();
	const classes = useStyles(props);
	const suggestionsNode = useRef(null);
	const popperNode = useRef(null);

	useEffect(() => {
		const organisationsListenerUnsubFunc = organisationsListener((o) => {
			dispatch({
				type: 'setNavigation',
				value: o,
			});
		});
		return () => organisationsListenerUnsubFunc();
	}, [dispatch]);

	function showOrganisationSearch() {
		dispatch({ type: 'open' });
		document.addEventListener('keydown', escFunction, false);
	}

	function hideOrganisationSearch() {
		dispatch({ type: 'close' });
		document.removeEventListener('keydown', escFunction, false);
	}

	function escFunction(event) {
		if (event.keyCode === 27) {
			hideOrganisationSearch();
		}
	}

	function handleSuggestionsFetchRequested({ value }) {
		dispatch({
			type: 'updateSuggestions',
			value,
		});
	}

	function handleSuggestionSelected(event, { suggestion }) {
		event.preventDefault();
		event.stopPropagation();

		rootDispatch(showMessage({ message: 'Skifter organisation...' }));

		const setAuthOrganisationCallable = firebaseService.getFunctions().httpsCallable('authSetOrganisation');

		setAuthOrganisationCallable({ organisationId: suggestion.id })
			.then((resp) => {
				rootDispatch(
					showMessage({
						message: 'Organisation blev skiftet. Genindlæser side...',
					})
				);
				firebaseService.refreshToken().then(() => {
					window.location.reload();
				});
			})
			.catch((err) => {
				rootDispatch(
					showMessage({
						message: `Fejl under organisations skift: ${err.message}`,
					})
				);
				console.error('setAuthOrganisationCallable ERROR', err);
			});

		hideOrganisationSearch();
	}

	function handleSuggestionsClearRequested() {
		dispatch({
			type: 'clearSuggestions',
		});
	}

	function handleChange(event) {
		dispatch({
			type: 'setSearchText',
			value: event.target.value,
		});
	}

	function handleClickAway(event) {
		return (
			(!suggestionsNode.current || !suggestionsNode.current.contains(event.target)) && hideOrganisationSearch()
		);
	}

	const autosuggestProps = {
		renderInputComponent,
		highlightFirstSuggestion: true,
		suggestions: state.suggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: handleSuggestionSelected,
		getSuggestionValue,
		renderSuggestion,
	};

	return (
		<div className={clsx(classes.root, 'flex', props.className)}>
			<Tooltip title="Skift organisation" placement="bottom">
				<div onClick={showOrganisationSearch} onKeyDown={showOrganisationSearch} role="button" tabIndex={0}>
					{props.trigger}
				</div>
			</Tooltip>

			{state.opened && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Paper className="absolute left-0 right-0 top-0 h-full z-9999" square>
						<div className="flex items-center w-full px-16" ref={popperNode}>
							<Icon className={classes.inputIcon}>business</Icon>
							<Autosuggest
								{...autosuggestProps}
								inputProps={{
									classes,
									placeholder: 'Organisation...',
									value: state.searchText,
									onChange: handleChange,
									InputLabelProps: {
										shrink: true,
									},
									autoFocus: true,
								}}
								theme={{
									container: 'flex flex-1 w-full',
									suggestionsList: classes.suggestionsList,
									suggestion: classes.suggestion,
								}}
								renderSuggestionsContainer={(options) => (
									<>
										{popperNode.current && (
											<Popper
												anchorEl={popperNode.current}
												// open={Boolean(options.children) || state.noSuggestions}
												modifiers={{
													preventOverflow: {
														padding: 0,
													},
												}}
												open
												popperOptions={{
													positionFixed: true,
												}}
												className="z-9999"
											>
												<div ref={suggestionsNode}>
													<Paper
														elevation={1}
														square
														{...options.containerProps}
														style={{
															width: popperNode.current
																? popperNode.current.clientWidth
																: null,
														}}
													>
														{options.children}
														{state.noSuggestions && (
															<Typography className="px-16 py-12">
																Ingen resultater..
															</Typography>
														)}
													</Paper>
												</div>
											</Popper>
										)}
									</>
								)}
							/>
							<IconButton onClick={hideOrganisationSearch} className="mx-8">
								<Icon>close</Icon>
							</IconButton>
						</div>
					</Paper>
				</ClickAwayListener>
			)}
		</div>
	);
}

OrganisationSelector.propTypes = {};
OrganisationSelector.defaultProps = {
	trigger: <OrganisationSelectorChip />,
};

export default React.memo(OrganisationSelector);
