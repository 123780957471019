import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'inline'
	},
	button: {},
	buttonSuccess: {
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: green[700]
		}
	},
	fabProgress: {
		color: green[500],
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

export default function LoadingButton(props) {
	const classes = useStyles(props);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	const handleButtonClick = e => {
		if (!loading && !props.disabled) {
			if (props.onClick) {
				props.onClick(e);
			}
			if (!props.disableLoadingOnClick) {
				setLoading(true);
			}
		}
	};

	return (
		<div className={clsx(classes.wrapper, props.className)}>
			<Button
				className={classes.button}
				variant={props.variant ? props.variant : 'contained'}
				color={props.color ? props.color : 'primary'}
				disabled={loading || props.disabled}
				onClick={handleButtonClick}
				startIcon={props.startIcon}
				endIcon={props.endIcon}
				type={props.type || 'button'}
				fullWidth={props.fullWidth || false}
				data-cy={'loadingBtn'}
			>
				{props.title || props.children}
			</Button>
			{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
		</div>
	);
}
