export default Object.freeze({
	PRODUCTS: 'products',
	PRODUCT_GROUPS: 'productGroups',
	CUSTOMERS: 'customers',
	CUSTOMER_TICKETS: 'customerTickets',
	CUSTOMER_SUBSCRIPTIONS: 'customerSubscriptions',
	ORDERS: 'orders',
	BIG_QUERY: 'bigQuery',
	LOCAL_ELASTIC_SEARCH: 'localElasticSearch',
	FLEXPOSORDERS: 'flexposOrders',
});
