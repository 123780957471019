import * as React from 'react';
import _ from 'lodash';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';

const _options = {
	spanGaps: false,
	maintainAspectRatio: false,
	layout: {
		padding: {
			left: 24,
			right: 32,
		},
	},
	scales: {
		xAxes: [
			{
				gridLines: {
					display: false,
					drawBorder: false,
					tickMarkLength: 18
				},
				ticks: {
					fontColor: '#ffffff'
				}
			}
		],
		yAxes: [
			{
				ticks: {
					min: 0
				}
			}
		]
	},
	elements: {
		point: {
			radius: 4,
			borderWidth: 2,
			hoverRadius: 4,
			hoverBorderWidth: 2,
		},
	},
	legend: {
		display: false,
	},
	tooltips: {
		position: 'nearest',
		mode: 'index',
		intersect: false,
	},
	plugins: {
		filler: {
			propagate: false,
		},
	},
};

function LineChart({ title, _data, optionProps = {} }) {
	const theme = useTheme();

	const options = _.merge(optionProps, _options);
	const data = _.merge({}, _data);

	_.setWith(options, 'scales.xAxes[0].ticks.fontColor', theme.palette.text.secondary);
	_.setWith(options, 'scales.yAxes[0].ticks.fontColor', theme.palette.text.secondary);
	_.setWith(options, 'scales.yAxes[0].gridLines.color', theme.palette.text.secondary);

	return (
		<Card className="w-full rounded-8 shadow-1">
			<div className="relative p-24 flex flex-row items-center justify-between">
				<div className="flex flex-col">
					<Typography className="h3 sm:h2">{title || ''}</Typography>
				</div>
			</div>

			<Typography className="relative h-200 sm:h-320 sm:pb-16">
				<Line
					data={{
						labels: data.labels,
						datasets: data.datasets.map((obj, index) => {
							const palette = theme.palette[index === 0 ? 'primary' : 'secondary'];
							return {
								...obj,
								borderColor: palette.main,
								backgroundColor: palette.main,
								pointBackgroundColor: palette.dark,
								pointHoverBackgroundColor: palette.main,
								pointBorderColor: palette.contrastText,
								pointHoverBorderColor: palette.contrastText,
							};
						}),
					}}
					options={options}
				/>
			</Typography>
		</Card>
	);
}

export default React.memo(LineChart);
