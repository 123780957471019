import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { countriesListener } from './shared/countrySlice';

function StoreInit({ children }) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (user && user.role && user.role.length > 0) {
			const unsub = dispatch(countriesListener());
			if (unsub) {
				return () => unsub();
			} else {
				return () => {};
			}
		}
		return () => {};
	}, [dispatch, user]);

	return children;
}

export default StoreInit;
