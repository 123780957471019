import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import CustomDateRangePicker from '@ameroservices-platform/shared/ui-components/CustomDateRangePicker';

function SelectComparePeriod({ onPeriodSelect, period }) {
	const [anchorEl, setAnchorEl] = useState('');
	const [dateRange, setDateRange] = useState([
		{
			startDate: moment(period.from).startOf('day').toDate(),
			endDate: moment(period.to).endOf('day').toDate(),
			key: 'selection',
		},
	]);

	useEffect(() => {
		setDateRange([
			{
				startDate: moment(period.from).startOf('day').toDate(),
				endDate: moment(period.to).endOf('day').toDate(),
				key: 'selection',
			},
		]);
	}, [period]);

	function handleClose() {
		onPeriodSelect({
			from: dateRange[0].startDate,
			to: dateRange[0].endDate,
		});
		setAnchorEl(null);
	}

	function handleChangeOnDatePicker(item) {
		setDateRange([item.selection]);
	}
	// function handleChangeOnDatePicker(item) {
	// 	setDateRange([
	// 		{
	// 			startDate: moment(item).startOf('day').toDate(),
	// 			endDate: moment(item).endOf('day').toDate(),
	// 			key: 'selection'
	// 		}
	// 	]);
	// }

	return (
		<>
			{ReactDOM.createPortal(
				<Popover
					id="compare-period"
					open={!!anchorEl}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Box>
						{/* <Calendar */}
						{/*	locale={da} */}
						{/*	date={dateRange[0].startDate} */}
						{/*	onChange={item => handleChangeOnDatePicker(item)} */}
						{/* /> */}
						<CustomDateRangePicker ranges={dateRange} onChange={(item) => handleChangeOnDatePicker(item)} />
					</Box>
				</Popover>,
				document.querySelector('#root')
			)}
			<button
				className="text-white px-12 py-5 shadow-md bg-[#4d88d1] hover:bg-[#233d68]"
				onClick={(e) => setAnchorEl(e.currentTarget)}
				style={{ minHeight: '37px', borderRadius: '4px' }}
			>
				{/* {`${moment(period.from).format('DD-MM-YYYY')}`} */}
				{moment(period.from).startOf('day').isSame(moment(period.to).startOf('day'))
					? `${moment(period.from).format('DD-MM-YYYY')}`
					: `${moment(period.from).format('DD-MM-YYYY')} - ${moment(period.to).format('DD-MM-YYYY')}`}
			</button>
		</>
	);
}

export default SelectComparePeriod;
