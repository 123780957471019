import React, { useEffect, useState } from 'react';
import { Chip, Icon } from '@mui/material';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

function OrganisationSelectorChip(props) {
	const [organisationName, setOrganisationName] = useState('Indlæser...');
	useEffect(() => {
		const organisationId = firebaseService.getOrganisationId();
		if (!organisationId) return setOrganisationName('Vælge organisation');
		const db = firebaseService.getOrganisationRootDB();
		return db.onSnapshot((doc) => {
			const docData = doc.data();
			if (!docData) {
				firebaseService.setOrganisationId(null);
				setOrganisationName('Vælg organisation');
			} else {
				const data = { ...docData, id: doc.id };
				setOrganisationName(data.name);
			}
		});
	}, []);

	return (
		<Chip
			icon={<Icon>business</Icon>}
			label={organisationName}
			onClick={(e) => {
				e.preventDefault();
			}}
			variant="outlined"
		/>
	);
}

// export default withReducer('quickPanel', reducer)(React.memo(QuickPanel));
export default OrganisationSelectorChip;
