import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

// const data = [
// 	{
// 		UID: '12',
// 		productName: 'Jonas',
// 		attributes: ['rød', 'blå', 'stor', 'ml 12'],
// 		amount: 1234,
// 	},
// 	{
// 		UID: '13',
// 		productName: 'Jonas hallo her',
// 		attributes: [],
// 		amount: 1234,
// 	},
// 	{
// 		UID: '14',
// 		productName: 'Jonas meget lang ting som der også',
// 		attributes: ['rød', 'blå', 'stor', 'ml 12'],
// 		amount: 1234,
// 	},
// 	{
// 		UID: '15',
// 		productName: 'Jonas jonas joasas',
// 		attributes: ['rød', 'blå', 'stor', 'ml 12'],
// 		amount: 1234,
// 	},
// 	{
// 		UID: '16',
// 		productName: 'Jonas jonas joasas hallo halalalaalaal',
// 		attributes: ['rød', 'blå', 'stor', 'ml 12'],
// 		amount: 1234,
// 	},
// ];

const Top5Widget = ({ top5, title }) => {
	return (
		<Card className="w-full rounded-8 shadow-1">
			<div className="relative p-24 flex flex-row items-center justify-between">
				<div className="flex flex-col">
					<Typography className="h3 sm:h2">{title || ''}</Typography>
				</div>
			</div>
			<div className="pb-16 w-full">
				{top5 &&
					top5.map((product, i) => (
						<div key={product.UID || i} className="flex py-3 px-16 items-center text-base w-full">
							<p className="align-top whitespace-nowrap font-bold text-lg">{i + 1} )</p>
							<div className="mx-8 grow min-w-0">
								<p className="truncate" title={product.productName || ''}>
									{product.productName || ''}
								</p>
								<p
									className="truncate italic text-sm text-gray-500"
									title={product.attributes.join(', ')}
								>
									{product.attributes.join(', ')}
								</p>
							</div>
							<p className="whitespace-nowrap ml-auto">{product.amount} stk.</p>
						</div>
					))}
			</div>
		</Card>
	);
};

export default Top5Widget;
