import FuseSplashScreen from '@ameroservices-platform/shipfromstore-backend/fuse/core/FuseSplashScreen';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from '@ameroservices-platform/shipfromstore-backend/app/store/fuse/messageSlice';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { setUserDataFirebase, setUserData, logoutUser } from './store/userSlice';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';

class Auth extends Component {
	state = {
		waitAuthCheck: true,
		ldProvider: null,
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			this.firebaseCheck(),
		])
			.then(() => {
				this.setState({ waitAuthCheck: false });

				return Promise.all([this.ldCheck(), this.configCheck()]);
			})
			.then(([LDProvider]) => {
				this.setState({ ldProvider: LDProvider });
			});
	}

	configCheck = async () => {
		if (firebaseService.getOrganisationId()) {
			await firebaseService.requestConfig();
		}
	};

	ldCheck = async () => {
		const user = {};
		if (this.props.user) {
			user.key = this.props.user.uid;
		}
		if (!user.key) {
			delete user.key;
			user.anonymous = true;
		}
		if (this.props.user && this.props.user.data) {
			if (this.props.user.data.displayName) {
				user.name = this.props.user.data.displayName;
			}
			if (this.props.user.data.email) {
				user.email = this.props.user.data.email;
			}
		}
		if (this.props.user && this.props.user.claims) {
			user.custom = {
				organisationUid: this.props.user.claims.organisationId || undefined,
				role: this.props.user.claims.role || undefined,
				email_verified: this.props.user.claims.email_verified || undefined,
				mode: this.props.user.claims.mode || undefined,
			};
		}
		const options = {};
		console.log('launchDarkly init with user and options: ', user, options);
		const LDProvider = await asyncWithLDProvider({
			clientSideID: process.env.REACT_APP_LD_CLIENTSIDEID || '6096f35fceeceb0de5e990c7',
			user,
			options,
		});
		if (process.env.REACT_APP_LD_CLIENTSIDEID) {
			console.log('USING ANOTHER CLIENT SIDE ID: ' + process.env.REACT_APP_LD_CLIENTSIDEID);
		}
		return LDProvider;
	};

	firebaseCheck = () =>
		new Promise((resolve) => {
			firebaseService.init(
				(success) => {
					if (!success) {
						resolve();
					}
					firebaseService.onAuthStateChanged((authUser) => {
						console.log('authUser', authUser);
						if (authUser && authUser.emailVerified === false) {
							firebaseService.sendEmailVerification().then(() => {
								firebaseService.signOut();
								console.log('Email verification mail re-send');
							});

							return;
						}

						if (authUser && authUser.emailVerified === true) {
							this.props.showMessage({ message: 'Logger på...' });

							firebaseService.getUserData(authUser.uid).then(
								(user) => {
									const claimsEmail = user.claims ? user.claims.email : '';
									const claimsRole = user.claims ? user.claims.role : 'user';
									const claimsOrganisationId =
										user.claims &&
										user.claims.organisationIds &&
										firebaseService.getOrganisationType() &&
										user.claims.organisationIds[firebaseService.getOrganisationType()]
											? user.claims.organisationIds[firebaseService.getOrganisationType()]
											: null;
									// const claimsSignedSearchKey = user.claims
									// 	? user.claims.signedSearchKey
									// 	: null;
									console.log('user', user);
									if (claimsEmail.endsWith('@amero.dk') && claimsRole !== 'ameroAdmin') {
										console.log(
											'Amero user without ameroAdmin role detected, requesting promotion'
										);
										firebaseService.requestAuthPromoteToAmeroAdmin().then(() => {
											console.log('Promoted, refreshing token');
											firebaseService.refreshToken().then(() => {
												console.log('Token refresh done, reloading app');
												window.location.reload();
											});
										});
										return;
									}
									if (
										claimsEmail.length > 0 &&
										!claimsOrganisationId &&
										claimsRole !== 'ameroAdmin'
									) {
										console.log(
											'Non ameroAdmin without organisationId, requesting change to default organisation'
										);
										const setAuthOrganisationCallable = firebaseService
											.getFunctions()
											.httpsCallable('authSetOrganisation');

										setAuthOrganisationCallable({})
											.then((resp) => {
												console.log('Organisation set, refreshing token');
												firebaseService.refreshToken().then(() => {
													console.log('Token refresh done, reloading app');
													window.location.reload();
												});
											})
											.catch((err) => {
												showMessage({
													message: `Fejl under tilknytning til organisation, kontakt Amero support`,
												});
												console.error(
													'auth, no organisationId, not ameroAdmin, trying to set default, setAuthOrganisationCallable ERROR',
													err
												);
											});

										return;
									}
									// if (
									// 	claimsOrganisationId &&
									// 	!claimsSignedSearchKey
									// ) {
									// 	console.log(
									// 		'OrganisationId exists but signedSearchKey does not, requesting change to same organisation'
									// 	);
									// 	const setAuthOrganisationCallable =
									// 		firebaseService
									// 			.getFunctions()
									// 			.httpsCallable(
									// 				'authSetOrganisation'
									// 			);
									//
									// 	setAuthOrganisationCallable({
									// 		organisationId:
									// 			claimsOrganisationId,
									// 	})
									// 		.then((resp) => {
									// 			console.log(
									// 				'Organisation & signedSearchkey set, refreshing token'
									// 			);
									// 			firebaseService
									// 				.refreshToken()
									// 				.then(() => {
									// 					console.log(
									// 						'Token refresh done, reloading app'
									// 					);
									// 					window.location.reload();
									// 				});
									// 		})
									// 		.catch((err) => {
									// 			showMessage({
									// 				message: `Fejl under tilknytning til organisation/signedSearchkey, kontakt Amero support`,
									// 			});
									// 			console.error(
									// 				'auth, no signedSearchkey, not ameroAdmin, trying to set default, setAuthOrganisationCallable ERROR',
									// 				err
									// 			);
									// 		});
									//
									// 	return;
									// }
									console.log('trying to use setUserDataFirebase');
									this.props.setUserDataFirebase(user, authUser);
									console.log('trying to use resolve');
									resolve();
									console.log('trying to use hideMessage');
									this.props.hideMessage();
								},
								(error) => {
									resolve();
								}
							);
						} else {
							resolve();
						}
					});
				},
				null,
				organisationType.SHIP_FROM_STORE
			);

			// if (!emailOfRequestingUser || ) {

			return Promise.resolve();
		});

	render() {
		if (this.state.waitAuthCheck) {
			return <FuseSplashScreen />;
		}
		if (this.state.ldProvider) {
			const LDComponent = this.state.ldProvider;
			return <LDComponent>{this.props.children}</LDComponent>;
		}

		return <FuseSplashScreen />;
	}
}

function mapStateToProps({ auth }) {
	return {
		user: auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataFirebase,
			showMessage,
			hideMessage,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
