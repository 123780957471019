import FuseNavigation from '@ameroservices-platform/shipfromstore-backend/fuse/core/FuseNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from '@ameroservices-platform/shipfromstore-backend/app/store/fuse/navigationSlice';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

function Navigation(props) {
	const organisationId = firebaseService.getOrganisationId();
	const navigation = useSelector(selectNavigation);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch();

	function handleItemClick(item) {
		if (mdDown) {
			dispatch(navbarCloseMobile());
		}
	}

	const filteredNavigation = useMemo(() => {
		return navigation.filter((navRow) => {
			return !(navRow.requireOrganisation && !organisationId);
		});
	}, [navigation, organisationId]);

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={filteredNavigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
			onItemClick={handleItemClick}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical',
};

export default memo(Navigation);
