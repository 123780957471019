import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const usersSlice = createSlice({
	name: 'shared/users',
	initialState: {
		users: {},
	},
	reducers: {
		addUser(state, action) {
			state.users[action.payload.id] = action.payload;
		},
	},
});

export const { addUser } = usersSlice.actions;

export const requestUser = (userId, isAmeroAdmin) => async (dispatch) => {
	const rootDb = firebaseService.getRootDB();
	const db = firebaseService.getOrganisationRootDB();
	let userData;
	if (isAmeroAdmin) {
		const userDoc = await rootDb.collection('users').doc(userId).get();
		userData = userDoc.data();
		if (userData && userData.data && userData.data.displayName) {
			userData = { name: userData.data.displayName };
		} else if (userData && userData.data && userData.data.email) {
			userData = { name: userData.data.email };
		} else {
			userData = { name: 'Ukendt' };
		}
	} else {
		const userDoc = await db.collection('organisation_users').doc(userId).get();
		userData = userDoc.data();
		if (userData && userData.displayName) {
			userData = { name: userData.displayName };
		} else if (userData && userData.email) {
			userData = { name: userData.email };
		} else {
			userData = { name: 'Amero' };
		}
	}
	if (!userData) {
		userData = { name: 'Amero' };
	}
	userData.id = userId;
	dispatch(addUser(userData));
};

export default usersSlice.reducer;

export const selectUsers = (state) => state.shared.users.users;
