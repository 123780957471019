import * as React from 'react';
import Top5Widget from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/Widgets/Top5Widget';
import DonutChart from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/Widgets/DonutChart';
import dashboardSlice, {
	requestData,
	selectWidget,
} from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/dashboardSlice';
import withReducer from '@ameroservices-platform/shipfromstore-backend/app/store/withReducer';
import LineChart from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/Widgets/LineChart';
import SelectPeriod from '@ameroservices-platform/shipfromstore-backend/app/main/apps/dashboard/SelectPeriod';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { ButtonGroup } from '@mui/material';
import moment from 'moment-timezone';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		background: `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
	},
}));

function DashboardPage() {
	const dispatch = useDispatch();

	const totalOrders = useSelector((state) => selectWidget(state, 'totalOrders'));
	const medianOrderValue = useSelector((state) => selectWidget(state, 'medianOrderValue'));
	const topProductsList = useSelector((state) => selectWidget(state, 'topProductsList'));
	const orderSuccess = useSelector((state) => selectWidget(state, 'orderSuccess'));

	const [period, setPeriod] = React.useState({
		from: moment().startOf('day').toDate(),
		to: moment().endOf('day').toDate(),
	});
	const [viewTime, setViewTime] = React.useState('dag');

	const classes = useStyles();

	React.useEffect(() => {
		dispatch(requestData(period.from.valueOf(), period.to.valueOf()));
	}, [period, dispatch]);

	const top5 = topProductsList.data
		? topProductsList.data.map((_data) => {
				return {
					..._data,
					attributes: [...(_data.size ? [_data.size] : []), ...(_data.color ? [_data.color] : [])],
				};
		  })
		: [];

	return (
		<div className="h-full w-full">
			<div className={'p-16 flex ' + classes.root}>
				<div className="ml-auto flex">
					<div className="flex-col">
						<ButtonGroup orientation="vertical" fullWidth>
							<SelectPeriod onPeriodSelect={setPeriod} period={period} />
						</ButtonGroup>
					</div>
				</div>
			</div>
			<div className="widget w-full p-16 grid grid-cols-3 gap-x-4">
				<div className="col-span-2">
					<Typography className="px-16 pt-16 pb-8 text-18 font-300">Antal Ordrer</Typography>
					<div className="widget w-full p-16 pb-32">
						<LineChart
							_data={{
								id: 'LineChartOrdersAmount',
								chartType: 'line',
								datasets: [
									{
										label: 'Antal ordrer',
										data: totalOrders.data,
										fill: 'start',
									},
								],
								labels: totalOrders.labels,
							}}
							optionProps={{
								scales: {
									xAxes: {
										grid: {
											display: false,
										},
										ticks: {
											fontColor: 'rgba(0,0,0,0.54)',
										},
									},
									yAxes: {
										gridLines: {
											tickMarkLength: 5,
										},
										ticks: {
											min: 0,
											stepSize: 5,
										},
									},
								},
							}}
						/>
					</div>
				</div>

				<div className="col-span-1">
					<Typography className="px-16 pt-16 pb-8 text-18 font-300">5 mest solgte produkter</Typography>
					<div className="widget w-full p-16 pb-32">
						<Top5Widget top5={top5} />
					</div>
				</div>

				<div className="col-span-2">
					<Typography className="px-16 pt-16 pb-8 text-18 font-300">Gennemsnits ordrerværdi</Typography>
					<div className="widget w-full p-16 pb-32">
						<LineChart
							_data={{
								id: 'LineChartOrdersAmount',
								chartType: 'line',
								datasets: [
									{
										label: 'Gennemsnits ordreværdi',
										data: medianOrderValue.data,
										fill: 'start',
									},
								],
								labels: medianOrderValue.labels,
							}}
							optionProps={{
								scales: {
									xAxes: {
										grid: {
											display: false,
										},
										ticks: {
											fontColor: 'rgba(0,0,0,0.54)',
										},
									},
									yAxes: {
										gridLines: {
											tickMarkLength: 5,
										},
										ticks: {
											min: 0,
											stepSize: 50,
										},
									},
								},
							}}
						/>
					</div>
				</div>
				<div className="col-span-1">
					<Typography className="px-16 pt-16 pb-8 text-18 font-300">Gennemførte ordrer</Typography>
					<div className="widget w-full p-16 pb-32">
						<DonutChart
							data={{
								id: 'widget7',
								labels: ['Gennemførte', 'Annulleret'],
								datasets: [
									{
										data: orderSuccess.data.percent,
										number: orderSuccess.data.number,
										change: [0, 0],
									},
								],
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withReducer('dashboardPage', dashboardSlice)(DashboardPage);
