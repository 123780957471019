import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const SysAdmin = lazy(() => import('./SysAdmin'));

const SysAdminAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/apps/sys-admin-settings',
			element: <SysAdmin />,
		},
	],
};

export default SysAdminAppConfig;
