import moment from 'moment-timezone';
import clsx from 'clsx';
import { da } from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const staticRangeHandler = {
	range: {},
	isSelected(range) {
		const definedRange = this.range();
		return (
			moment(range.startDate).isSame(moment(definedRange.startDate)) &&
			moment(range.endDate).isSame(moment(definedRange.endDate))
		);
	},
};
function CustomDateRangePicker(props) {
	const useStyles = makeStyles((theme) => ({
		weekNumber: {
			fontSize: '0.7em',
			left: '-9px',
			top: '-5px',
			color: '#000',
			fontWeight: 'bold',
		},
		root: {
			'& .rdrDefinedRangesWrapper': {
				display: props.customStaticRanges?.length === 0 ? 'none' : 'block',
			},
			'& .rdrStaticRange.rdrStaticRangeSelected': {
				color: theme.palette.primary.main
			},
			'& .rdrStaticRange': {
				color: theme.palette.text.primary
			},
		},
		disabled: {
			cursor: 'not-allowed',
			opacity: 0.75,
			'& .rdrDay': {
				cursor: 'not-allowed',
			},
			'& .rdrDayStartPreview': {
				border: 'none',
			},
			'& .rdrDayInPreview': {
				border: 'none',
			},
			'& .rdrDayEndPreview': {
				border: 'none',
			},
		},
	}));
	const classes = useStyles();

	return (
		<DateRangePicker
			dayContentRenderer={(date) => {
				const momentDate = moment(date);
				return (
					<>
						{momentDate.day() === 1 && (
							<div className={clsx(classes.weekNumber, 'absolute')}>{momentDate.isoWeek()}</div>
						)}
						<span>{momentDate.date()}</span>
					</>
				);
			}}
			locale={da}
			staticRanges={
				props.customStaticRanges
					? props.customStaticRanges.map((range) => ({
							...staticRangeHandler,
							...range,
					  }))
					: [
							{
								label: 'I morgen',
								range: () => ({
									startDate: moment().add(1, 'day').startOf('day').toDate(),
									endDate: moment().add(1, 'day').endOf('day').toDate(),
								}),
							},
							{
								label: 'I dag',
								range: () => ({
									startDate: moment().startOf('day').toDate(),
									endDate: moment().endOf('day').toDate(),
								}),
							},
							{
								label: 'I går',
								range: () => ({
									startDate: moment().subtract(1, 'day').startOf('day').toDate(),
									endDate: moment().subtract(1, 'day').endOf('day').toDate(),
								}),
							},
							{
								label: 'Næste uge',
								range: () => ({
									startDate: moment().add(1, 'week').startOf('week').toDate(),
									endDate: moment().add(1, 'week').endOf('week').toDate(),
								}),
							},
							{
								label: 'Denne uge',
								range: () => ({
									startDate: moment().startOf('week').toDate(),
									endDate: moment().endOf('week').toDate(),
								}),
							},
							{
								label: 'Sidste uge',
								range: () => ({
									startDate: moment().subtract(1, 'week').startOf('week').toDate(),
									endDate: moment().subtract(1, 'week').endOf('week').toDate(),
								}),
							},
							{
								label: 'Næste måned',
								range: () => ({
									startDate: moment().add(1, 'month').startOf('month').toDate(),
									endDate: moment().add(1, 'month').endOf('month').toDate(),
								}),
							},
							{
								label: 'Denne måned',
								range: () => ({
									startDate: moment().startOf('month').toDate(),
									endDate: moment().endOf('month').toDate(),
								}),
							},
							{
								label: 'Sidste måned',
								range: () => ({
									startDate: moment().subtract(1, 'month').startOf('month').toDate(),
									endDate: moment().subtract(1, 'month').endOf('month').toDate(),
								}),
							},
					  ].map((range) => ({ ...staticRangeHandler, ...range }))
			}
			inputRanges={[]}
			className={clsx(props.className, classes.root, props.disabled ? classes.disabled : null)}
			{...props}
		/>
	);
}

export default CustomDateRangePicker;
