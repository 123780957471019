import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const ExportJobExportJob = lazy(() => import('./exportJob/ExportJob'));
const ExportJobsExportJobs = lazy(() => import('./exportJobs/ExportJobs'));

const ExportJobsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/reports/:exportJobUid',
			element: <ExportJobExportJob />,
		},
		{
			path: '/apps/reports',
			element: <ExportJobsExportJobs />,
		},
	],
};

export default ExportJobsAppConfig;
