import React from 'react';
import authRoles from '../../../auth/authRoles';

const NewOrderNewOrder = React.lazy(() => import('./newOrder/NewOrder'));
const OrderOrder = React.lazy(() => import('./order/Order'));
const OrdersOrders = React.lazy(() => import('./orders/Orders'));
const RefundsRefunds = React.lazy(() => import('./refunds/Refunds'));

const OrdersAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/orders/new/:customerUid/:orderUid',
			element: <NewOrderNewOrder />,
		},
		{
			path: '/apps/orders/new',
			element: <NewOrderNewOrder />,
		},
		{
			path: '/apps/orders/detail/:customerUid/:orderUid',
			element: <OrderOrder />,
		},
		{
			path: '/apps/orders',
			element: <OrdersOrders />,
		},
		{
			path: '/apps/carts/:customerUid/:orderUid',
			element: <NewOrderNewOrder />,
		},
		{
			path: '/apps/carts',
			element: <OrdersOrders />,
		},
		{
			path: '/apps/refunds',
			element: <RefundsRefunds />,
		},
	],
};

export default OrdersAppConfig;
