import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMainTheme } from '../../store/fuse/settingsSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import {
	AppBar,
	Autocomplete,
	ButtonGroup,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles';

const modes = { developer: 'Udvikler', beta: 'Beta', null: 'Ingen' };
function BetaDialog({ open, ...props }) {
	const mainTheme = useSelector(selectMainTheme);
	const [loading, setLoading] = useState(false);
	const user = useSelector(({ auth }) => auth.user);
	const [mode, setMode] = useState(null);

	useEffect(() => {
		if (user && user.claims) {
			setMode(user.claims.mode || null);
		}
	}, [user]);

	function onClose(success) {
		if (success) {
			setLoading(true);
			const authSetUserModeCallable = firebaseService.getFunctions().httpsCallable('authSetUserMode');

			authSetUserModeCallable({ mode })
				.then(() => {
					return firebaseService.refreshToken();
				})
				.then(() => {
					setTimeout(() => {
						window.location.reload();
						props.onClose();
						setLoading(false);
					}, 2500);
				})
				.catch((err) => {
					console.error('authSetUserModeCallable ERROR', err);
					setLoading(false);
					props.onClose();
				});
		} else {
			props.onClose();
		}
		if (user && user.claims) {
			setMode(user.claims.mode || null);
		}
	}
	return (
		<ThemeProvider theme={mainTheme}>
			<Dialog
				open={open}
				onClose={() => onClose(false)}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="xs"
				classes={{
					paper: 'rounded-8',
				}}
			>
				<AppBar position="static">
					<Toolbar className="flex w-full">
						<Typography variant="subtitle1" color="inherit">
							Din Tilstand
						</Typography>
					</Toolbar>
				</AppBar>

				<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
					{loading ? (
						<>
							<Grid container spacing={1}>
								<Grid item sm={12}>
									<div className="flex justify-center mt-24">
										<CircularProgress className="mt-24" color="primary" size="10rem" />
									</div>
								</Grid>
								<Grid item sm={12}>
									<div className="flex justify-center pt-16 pb-16">
										<Typography className="text-20" color="textSecondary">
											Skifter tilstand...
										</Typography>
									</div>
								</Grid>
							</Grid>
						</>
					) : (
						<div className="flex justify-center">
							<Autocomplete
								options={Object.keys(modes)}
								value={mode || 'null'}
								onChange={(event, newValue) => {
									setMode(newValue);
								}}
								getOptionLabel={(option) => modes[option]}
								renderInput={(params) => <TextField {...params} label="Tilstand" variant="outlined" />}
								variant="outlined"
								openOnFocus
								disableClearable
								required
								fullWidth
								className="w-full"
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions className="justify-center p-8 mt-10">
					<ButtonGroup fullWidth className="w-full">
						<Button variant="contained" onClick={() => onClose(false)}>
							Annuller
						</Button>
						<Button variant="contained" color="primary" onClick={() => onClose(true)}>
							Skift
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}
export default BetaDialog;
