import firebaseService from '@ameroservices-platform/shared/services/firebase';
const { createSlice } = require('@reduxjs/toolkit');

const dashboardSlice = createSlice({
	name: 'dashboardPage',
	initialState: {
		widgetData: {
			totalOrders: {
				data: [],
				labels: [],
			},
			medianOrderValue: {
				data: [],
				labels: [],
			},
			topProductsList: {
				data: [],
				labels: [],
			},
			orderSuccess: {
				data: {
					number: [],
					percent: [],
				},
			},
		},
	},
	reducers: {
		setWidgetData(state, action) {
			state.widgetData = action.payload;
		},
	},
});

export const { setWidgetData } = dashboardSlice.actions;

export const requestData =
	(from, to, test = false) =>
	async (dispatch) => {
		try {
			const response = await firebaseService.callFunctionByName('bigQueryGetDashboardStats', {
				from,
				to,
				test,
			});
			if (response) {
				dispatch(setWidgetData(response.data));
			}
		} catch (e) {
			console.error(e);
		}
	};

export default dashboardSlice.reducer;

export const selectWidget = (state, id) => state.dashboardPage.widgetData[id];
// export const selectWidget = (state, id) => state;
