import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Doughnut } from 'react-chartjs-2';
import Icon from '@mui/material/Icon';
import _ from 'lodash';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';

const _options = {
	cutoutPercentage: 75,
	spanGaps: false,
	tooltips: {
		callbacks: {
			label: function (context) {
				let label = context.label || '';
				if (label) {
					return `${label}: ${context.parsed}%`;
				}
			},
		},
	},
	legend: {
		display: false,
	},
	maintainAspectRatio: false,
};
const DonutChart = (props) => {
	const theme = useTheme();
	const options = _.merge({}, _options);
	const data = _.merge({}, props.data);

	// console.log(data);

	return (
		<Card className="w-full rounded-8 shadow-1">
			<div className="p-16">
				<Typography className="h1 font-300">{props.title}</Typography>
			</div>

			<div className="h-224 relative">
				<Doughnut
					data={{
						labels: data.labels,
						datasets: data.datasets.map((obj) => ({
							...obj,
							data: obj.data.map((num) => Math.round(num)),
							borderColor: theme.palette.divider,
							backgroundColor: [
								theme.palette.primary.dark,
								theme.palette.secondary.main,
								theme.palette.primary.light,
							],
							hoverBackgroundColor: [
								theme.palette.primary.dark,
								theme.palette.secondary.main,
								theme.palette.primary.light,
							],
						})),
					}}
					options={options}
				/>
			</div>

			<div className="p-16 flex flex-row items-center justify-center">
				{data.labels.map((label, index) => (
					<div key={label} className="px-16 flex flex-col items-center">
						<Typography className="h4" color="textSecondary">
							{label}
						</Typography>
						<Typography className="h2 font-300 py-8">{data.datasets[0].number[index]}</Typography>

						{data.datasets[0].change[index] !== 0 && (
							<div className="flex flex-row items-center justify-center">
								{data.datasets[0].change[index] < 0 && (
									<Icon className="text-18 text-red">arrow_downward</Icon>
								)}

								{data.datasets[0].change[index] > 0 && (
									<Icon className="text-18 text-green">arrow_upward</Icon>
								)}
								<div className="h5 px-4">{_.round(data.datasets[0].change[index], 2)}%</div>
							</div>
						)}
					</div>
				))}
			</div>
		</Card>
	);
};

export default DonutChart;
